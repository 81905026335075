import Layout from "../components/layouts/layout/layout"
import FeatureContent from "../components/layouts/feature-content/feature-content"
import HeroImage from "../components/layouts/hero/hero-image"
import Newsletter from "../components/layouts/newsletter/newsletter"
import Quickstart from "../components/layouts/quickstart/quickstart"
import Stats from "../components/layouts/stats/stats"
import * as content from "../page-content/content-nextjs"
import { opensourceQuickstart } from "../page-content/shared/content-opensource"
import { sharedStats } from "../page-content/shared/content-stats"

const NextJSPage = () => (
  <Layout>
    <HeroImage {...content.hero} />
    <FeatureContent {...content.featureContentVideo} />
    <FeatureContent {...content.featureContentCoding} />
    <Newsletter preselect="kratos" />
    <Stats {...sharedStats} />
    <Quickstart {...opensourceQuickstart} />
  </Layout>
)

export const Head = () => {
  return (
    <>
      <title>{content.seo.title}</title>
      {content.seo.description && (
        <meta name="description" content={content.seo.description} />
      )}
      <meta property="og:title" content={content.seo.title} />
      {content.seo.description && (
        <meta property="og:description" content={content.seo.description} />
      )}
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:author" content="Ory" />
      <meta property="twitter:title" content={content.seo.title} />
      {content.seo.description && (
        <meta
          property="twitter:description"
          content={content.seo.description}
        />
      )}
    </>
  )
}

export default NextJSPage
